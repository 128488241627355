import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey:
    '20020900459-jgt9pp1puq45igfi5lc50j25k14ccg8r.apps.googleusercontent.com',
  authDomain: 'workout-tracker-20211028.firebaseapp.com',
  //   databaseURL: "https://workout-tracker-20211028.firebaseio.com",
  //   storageBucket: "workout-tracker-20211028.appspot.com",
}
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

async function login() {
  // Sign in using a popup.
  const provider = new GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')
  const result = await signInWithPopup(auth, provider)

  // The signed-in user info.
  const user = result.user
  // This gives you a Google Access Token.
  const credential = provider.credentialFromResult(auth, result)
  const token = credential.accessToken

  console.log(user, credential, token)
}

login();